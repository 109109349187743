import {
  TOGGLE_SIDEBAR,
  TOGGLE_MODAL,
  UPDATE_FORMEL,
  TOGGLE_TOPDRAWER,
  TOGGLE_CONTACT,
  SET_ASSETS
} from '../actiontypes/components'

interface ComponentReducer {
  sidebar: boolean
  modal: boolean
  topDrawer: boolean
  forms: any[]
  contact: boolean
  modalState: any
  assets: any[]
}

const initialState: ComponentReducer = {
  sidebar: false,
  modal: false,
  topDrawer: false,
  forms: [],
  contact: false,
  assets: [],
  modalState: {
    isOpen: false,
    confirmAction: false,
    content: null,
    defaultState: true,
    isLoading: false,
    labelOne: null,
    labelTwo: null,
    onlyClose: false,
    showButtons: false,
    toggleModal: null,
    title: ""
  }
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return {
        ...state,
        sidebar: action.bool
      };
    case TOGGLE_MODAL:
      return {
        ...state,
        modalState: { ...action.payload }
      };
    case TOGGLE_TOPDRAWER:
      return {
        ...state,
        topDrawer: action.bool
      };
    case TOGGLE_CONTACT:
      return {
        ...state,
        contact: action.bool
      };
    case SET_ASSETS:
    return {
      ...state,
      assets: action.payload.map(image => image)
    };
    case UPDATE_FORMEL:
      return {
        ...state,
        forms: [
          ...state.forms.slice(0, action.index),
          {
            name: action.formName,
            fieldData: action.fieldData
          }
        ]
      };

    default:
      return state;
  }
}

