// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-signup-tsx": () => import("/opt/build/repo/src/templates/signup.tsx" /* webpackChunkName: "component---src-templates-signup-tsx" */),
  "component---src-templates-page-tsx": () => import("/opt/build/repo/src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-app-tsx": () => import("/opt/build/repo/src/templates/app.tsx" /* webpackChunkName: "component---src-templates-app-tsx" */),
  "component---src-templates-blog-list-tsx": () => import("/opt/build/repo/src/templates/blogList.tsx" /* webpackChunkName: "component---src-templates-blog-list-tsx" */),
  "component---src-templates-blog-entry-tsx": () => import("/opt/build/repo/src/templates/blogEntry.tsx" /* webpackChunkName: "component---src-templates-blog-entry-tsx" */),
  "component---src-pages-404-tsx": () => import("/opt/build/repo/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-tsx": () => import("/opt/build/repo/src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-signin-tsx": () => import("/opt/build/repo/src/pages/signin.tsx" /* webpackChunkName: "component---src-pages-signin-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

