export const ADJUST_DECK = 'list/ADJUST_DECK'
export const ADJUST_HAND = 'list/ADJUST_HAND'
export const CALCULATE_COMBOS = 'list/CALCULATE_COMBOS'
export const GENERATE_DOWNLOAD = 'list/GENERATE_DOWNLOAD'
export const GET_CARDS = 'list/GET_CARDS'
export const LOAD_CONFIG = 'list/LOAD_CONFIG'
export const LOAD_DECKS = 'list/LOAD_DECKS'
export const REMOVE_CARD = 'list/REMOVE_CARD'
export const REMOVE_COMBO = 'list/REMOVE_COMBO'
export const REMOVE_CONFIG = 'list/REMOVE_CONFIG'
export const RESET_VALUES = 'list/RESET_VALUES'
export const RUN_OPTIMIZER = 'list/RUN_OPTIMIZER'
export const SEARCH_CARDS = 'list/SEARCH_CARDS'
export const TOGGLE_ATTACK = 'list/TOGGLE_ATTACK'
export const TOGGLE_CARD = 'list/TOGGLE_CARD'
export const TOGGLE_COMBO = 'list/TOGGLE_COMBO'
export const TOGGLE_DEFENSE = 'list/TOGGLE_DEFENSE'
export const TOGGLE_SPEED = 'list/TOGGLE_SPEED'
export const UPDATE_CARD_ATTR = 'list/UPDATE_CARD_ATTR'
export const UPDATE_CARDLIST = 'list/UPDATE_CARDLIST'
export const UPDATE_LEVEL = 'list/UPDATE_LEVEL'
export const UPDATE_MESSAGE = 'list/UPDATE_MESSAGE'
export const UPDATE_SCALE = 'list/UPDATE_SCALE'
export const UPDATE_TICK = 'list/UPDATE_TICK'
export const UPDATE_VALUE = 'list/UPDATE_VALUE'
export const REFRESH_CARDS = 'list/REFRESH_CARDS'
export const CLEAR_ALL = 'list/CLEAR_ALL'
export const SET_TEMP = 'list/SET_TEMP'
export const SET_OP_STATE = 'list/SET_OP_STATE'
export const SET_PROB_STATE = 'list/SET_PROB_STATE'
export const TOGGLE_SEARCH = 'list/TOGGLE_SEARCH'