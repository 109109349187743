import React from 'react'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './reducers/index'

const middleware = [thunk]
const options = { trace: true }

function devTools() {
  if (process.env.MODE === 'DEV') {
      return (
        (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
        (window as any).__REDUX_DEVTOOLS_EXTENSION__(options)
      );
  }
  return null;
}


const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(...middleware),
    
    // (devTools())
  )
)

const reduxWrapper = ({ element }) => (
  <Provider store={store}>{element}</Provider>
)

export default reduxWrapper;

