import { AUTHENTICATE_USER } from '../actiontypes/auth'

const initalState: any = {
  _user: null,
  user: null,
  isActive: false,
  isLoading: true
}

export default (state = initalState, action: any) => {
  switch (action.type) {
    case AUTHENTICATE_USER:
      return {
        ...state,
        _user: action._user,
        user: action.user,
        isActive: action.isActive,
        isLoading: false
      }
    default:
      return state
  }
}