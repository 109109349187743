import {
  GET_CARDS,
  UPDATE_VALUE,
  UPDATE_TICK,
  UPDATE_LEVEL,
  UPDATE_SCALE,
  SEARCH_CARDS,
  UPDATE_MESSAGE,
  TOGGLE_CARD,
  UPDATE_CARD_ATTR,
  TOGGLE_COMBO,
  REMOVE_COMBO,
  ADJUST_DECK,
  CALCULATE_COMBOS,
  UPDATE_CARDLIST,
  RUN_OPTIMIZER,
  GENERATE_DOWNLOAD,
  LOAD_DECKS,
  LOAD_CONFIG,
  TOGGLE_ATTACK,
  TOGGLE_DEFENSE,
  TOGGLE_SPEED,
  RESET_VALUES,
  REMOVE_CARD,
  REFRESH_CARDS,
  CLEAR_ALL,
  SET_TEMP,
  SET_OP_STATE,
  SET_PROB_STATE,
  TOGGLE_SEARCH
} from "../actiontypes/cards";

const initialState: CardsReducer = {
  cardList: [],
  // Selected options (User changes)
  selectedCategory: "All Cards",
  selectedCategoryM: "All Categories",
  selectedCategoryS: "All Categories",
  selectedCategoryT: "All Categories",
  selectedAttribute: "All Attributes",
  selectedType: "All Types",
  selectedLevel: 4,
  selectedScale: 3,
  isNormal: false,
  isEffect: false,
  isFusion: false,
  isRitual: false,
  isSynchro: false,
  isXyz: false,
  isPendulum: false,
  isLink: false,
  isSpecialSum: false,
  isTuner: false,
  isGemini: false,
  isUnion: false,
  isSpirit: false,
  isFlip: false,
  isToon: false,
  isNomi: false,
  isContinuous: false,
  isQuick: false,
  isEquip: false,
  isField: false,
  isCounter: false,
  // User text input
  cardName: "",
  // Search query results
  queryResults: [],
  // Sliders enabled/disabled
  levelDisabled: true,
  scaleDisabled: true,
  statusMsg: "",
  deckSize: 40,
  handFirst: true,
  // Probability
  combos: {},
  optimalResults: [],
  download: null,
  decks: [],
  defense: 1,
  attack: 1,
  speed: 1,
  temp: [],
  optimizing: false,
  optimizerError: false,
  optimizerMsg: "",
  calculatingProbs: false,
  probError: false,
  probMsg: "",
  isSearching: false
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case GET_CARDS:
      return {
        ...state,
        token: action.token,
        user: action.user
      };
    case RESET_VALUES:
      return {
        ...state,
        isNormal: false,
        isEffect: false,
        isFusion: false,
        isRitual: false,
        isSynchro: false,
        isXyz: false,
        isPendulum: false,
        isLink: false,
        isSpecialSum: false,
        isTuner: false,
        isGemini: false,
        isUnion: false,
        isSpirit: false,
        isFlip: false,
        isToon: false,
        isNomi: false,
        isContinuous: false,
        isQuick: false,
        isEquip: false,
        isField: false,
        isCounter: false
      };
    case UPDATE_VALUE:
      return {
        ...state,
        [action.value.key]: action.value.payload
      };
    case UPDATE_TICK:
      return {
        ...state,
        [action.value.key]: action.value.payload
      };
    case UPDATE_LEVEL:
      return {
        ...state,
        selectedLevel: action.selectedLevel
      };
    case UPDATE_SCALE:
      return {
        ...state,
        selectedScale: action.selectedScale
      };
    case SEARCH_CARDS:
      return {
        ...state,
        queryResults: action.payload.map(item => item)
      };
    case UPDATE_MESSAGE:
      return {
        ...state,
        statusMsg: action.payload
      };
    case TOGGLE_CARD:
      return {
        ...state,
        cardList: action.payload.map(item => item)
      };
    case UPDATE_CARD_ATTR:
      return {
        ...state,
        cardList: action.payload.map(item => item)
      };
    case TOGGLE_COMBO:
      return {
        ...state,
        cardList: action.payload.map(item => item)
      };
    case REMOVE_COMBO:
      return {
        ...state,
        cardList: action.payload.map(item => item)
      };
    case ADJUST_DECK:
      return {
        ...state,
        deckSize: action.payload
      };
    case CALCULATE_COMBOS:
      return {
        ...state,
        combos: action.payload
      };
    case UPDATE_CARDLIST:
      return {
        ...state,
        cardList: action.payload.map(item => item)
      };
    case RUN_OPTIMIZER:
      return {
        ...state,
        optimalResults: action.payload.map(item => item)
      };
    case GENERATE_DOWNLOAD:
      return {
        ...state,
        download: action.payload
      };
    case LOAD_DECKS:
      return {
        ...state,
        decks: action.payload.map(item => item)
      };
    case LOAD_CONFIG:
      return {
        ...state,
        cardList: action.cardList.map(item => item),
        combos: { ...action.combos },
        optimalResults: action.optimalResults.map(item => item)
      };
    case TOGGLE_ATTACK:
      return {
        ...state,
        attack: action.payload
      };
    case TOGGLE_DEFENSE:
      return {
        ...state,
        defense: action.payload
      };
    case TOGGLE_SPEED:
      return {
        ...state,
        speed: action.payload
      };
    case REMOVE_CARD:
      return {
        ...state,
        cardList: action.payload.map(item => item)
      };
    case REFRESH_CARDS:
      return {
        ...state,
        cardList: action.payload.map(item => item)
      };
    case CLEAR_ALL:
      return {
        ...state,
        cardList: [],
        combos: {},
        optimalResults: []
      };
    case SET_TEMP:
      return {
        ...state,
        temp: action.temp.map(item => item)
      };
    case SET_OP_STATE:
      return {
        ...state,
        optimizing: action.optimizing,
        optimizerError: action.optimizerError,
        optimalResults: action.optimalResults,
        optimizerMsg: action.optimizerMsg
      };
    case SET_PROB_STATE:
      return {
        ...state,
        calculatingProbs: action.calculatingProbs,
        probError: action.probError,
        probMsg: action.probMsg,
        combos: { ...action.combos }
      };
    case TOGGLE_SEARCH:
      return {
        ...state,
        isSearching: action.payload
      };
    default:
      return state;
  }
};

interface CardsReducer {
  attack: number;
  cardList: any[];
  cardName: string;
  combos: {};
  decks: any[];
  deckSize: number;
  defense: number;
  download: any;
  handFirst: boolean;
  isContinuous: boolean;
  isCounter: boolean;
  isEffect: boolean;
  isEquip: boolean;
  isField: boolean;
  isFlip: boolean;
  isFusion: boolean;
  isGemini: boolean;
  isLink: boolean;
  isNomi: boolean;
  isNormal: boolean;
  isPendulum: boolean;
  isQuick: boolean;
  isRitual: boolean;
  isSpecialSum: boolean;
  isSpirit: boolean;
  isSynchro: boolean;
  isToon: boolean;
  isTuner: boolean;
  isUnion: boolean;
  isXyz: boolean;
  levelDisabled: boolean;
  optimalResults: any[];
  queryResults: any;
  scaleDisabled: boolean;
  selectedAttribute: string;
  selectedCategory: string;
  selectedCategoryM: string;
  selectedCategoryS: string;
  selectedCategoryT: string;
  selectedLevel: number;
  selectedScale: number;
  selectedType: string;
  speed: number;
  statusMsg: string;
  temp: any[];
  optimizing: boolean;
  optimizerError: boolean;
  optimizerMsg: string;
  calculatingProbs: boolean;
  probError: boolean;
  probMsg: string;
  isSearching: boolean;
}
